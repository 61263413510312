import bg from "../assets/bg.png";
import chef from "../assets/chef.png";
import biryani from "../assets/biryani.jpeg";
import kebab from "../assets/kebab.jpeg";
import knife from "../assets/knife.png";
import logo from "../assets/logo.png";
import menu from "../assets/menu.png";
import breast from "../assets/chicken breast.jpeg";
import seek from "../assets/seekh.jpeg";
import chickenLeg from "../assets/chickenLeg.jpeg";
import rice from "../assets/rice.jpeg";

import overlaybg from "../assets/overlaybg.png";
import spoon from "../assets/spoon.svg";
import welcome from "../assets/welcome.png";
import findus from "../assets/findus.png";
import laurels from "../assets/laurels.png";
import award01 from "../assets/award01.png";
import award02 from "../assets/award02.png";
import award03 from "../assets/award03.png";
import award05 from "../assets/award05.png";
import sign from "../assets/sign.png";
import quote from "../assets/quote.png";

export default {
  bg,
  chef,
  rice,
  chickenLeg,
  seek,
  breast,
  biryani,
  kebab,
  knife,
  logo,
  menu,
  overlaybg,
  spoon,
  welcome,
  findus,
  laurels,
  award01,
  award02,
  award03,
  award05,
  sign,
  quote,
};
