import React from "react";

import { SubHeading } from "../../components";
import { images } from "../../constants";
const FindUs = () => (
  <div className="app__bg app__wrapper section__padding" id="location">
    <div className="app__wrapper_info">
      <SubHeading title="Location" />
      <h1
        className="headtext__cormorant"
        style={{
          marginBottom: "3rem",
        }}
      >
        find us
      </h1>
      <div className="app__wrapper-content">
        <p className="p__opensans">283 OldBury Rd, Rowley Regis, Birmingham</p>
        <p
          className="p__cormorant"
          style={{
            color: "#DCCA87",
            margin: "2rem 0",
          }}
        >
          Opening Hours
        </p>
        <p className="p__opensans">Mon-Fri: 1:00 pm - 11:00 pm</p>
        <p className="p__opensans">Sat - Sun: 10:00 am - 11:00 pm</p>
      </div>
      <form action="https://goo.gl/maps/aZmVaTi69ASF6GsYA">
        <button
          className="custom__button"
          type="submit"
          formtarget="_blank"
          style={{ marginTop: "2rem" }}
        >
          Visit Us
        </button>
      </form>
    </div>
    <div className="app__wrapper_img">
      <img src={images.findus} alt="findus" />
    </div>
  </div>
);

export default FindUs;
