const starters = [
  {
    title: "Zahra's Fries",
    price: "£2.99",
    tags: "Potato | Veg",
  },
  {
    title: "Shami Kebab",
    price: "£1.00",
    tags: "Beef | Mutton",
  },
  {
    title: "Croquettes",
    price: "£0.90",
    tags: "Chicken & Vegetables",
  },
  {
    title: "Tandoori Chicken Wings",
    price: "£3.99 - 7 wings",
    tags: "Chicken | Roast",
  },
  {
    title: "Fruit Chaat",
    price: "£2.00",
    tags: "Fruites | Chaat",
  },
  {
    title: "Zahra's Salad",
    price: "£2.99",
    tags: "vegies",
  },
];

const main = [
  {
    title: "Zahra's Tikka Biryani",
    price: "£8.99",
    tags: "Chicken | Special",
  },
  {
    title: "Special Saver Pilau",
    price: "£9.50",
    tags: "Roasted Chicken | Shami Kebab",
  },
  {
    title: "Chicken Yakhni Pilau",
    price: "£7.99",
    tags: "Chicken | Rice | Special",
  },
  {
    title: "Spicy Karachi Biryani",
    price: "£8.50",
    tags: "Chicken | Rice",
  },
  {
    title: "Chineese Chicken Rice",
    price: "£7.99",
    tags: "Chicken | vegetable | Rice",
  },
];

// const awards = [
//   {
//     imgUrl: images.award02,
//     title: 'Bib Gourmond',
//     subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
//   },
//   {
//     imgUrl: images.award01,
//     title: 'Rising Star',
//     subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
//   },
//   {
//     imgUrl: images.award05,
//     title: 'AA Hospitality',
//     subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
//   },
//   {
//     imgUrl: images.award03,
//     title: 'Outstanding Chef',
//     subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
//   },
//];

export default { starters, main };
